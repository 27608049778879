import { Component, EventEmitter, Output } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { MaterialsService } from "../../../../../pages/principal/materials/materials.service";
import { ToolsService } from "../../../../../pages/principal/tools/tools.service";

@Component({
  selector: "app-popover-create-tool-type",
  templateUrl: "./create-tool-type.component.html",
  styleUrls: ["./create-tool-type.component.css"],
})
export class CreateToolTypeComponent {
  @Output() createToolTypeEvent = new EventEmitter();

  constructor(
    private alertController: AlertController,
    private toolsService: ToolsService
  ) {}

  async createType() {
    const alert = await this.alertController.create({
      header: "Añadir tipo de herramienta",
      inputs: [
        {
          name: "Name",
          type: "text",
          placeholder: "Introduce el nombre",
        },
      ],
      buttons: [
        {
          text: "¡Cancelar!",
          role: "cancel",
          handler: () => {},
        },
        {
          text: "¡Añadir!",
          handler: (res) => {
            this.toolsService.addToolType(res.Name).then((type) => {
              console.log(type);
              this.createToolTypeEvent.emit(true);
            });
          },
        },
      ],
    });

    await alert.present();
  }
}
