import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  public loading: boolean;

  public subs = null;

  constructor(public loadingController: LoadingController) {}

  async present(loadingId: string, loadingMessage: string) {
    const loading = await this.loadingController.create({
      id: loadingId,
      message: loadingMessage,
    });
    return await loading.present();
  }

  async dismiss(loadingId: string) {
    return await this.loadingController.dismiss(null, null, loadingId);
  }

  _startLoading() {
    this.loadingController.getTop().then((hasLoading) => {
      if (!hasLoading) {
        this.loadingController
          .create({
            spinner: "crescent",
            translucent: true,
            message: "Cargando...",
            showBackdrop: true,
            backdropDismiss: false,
            mode: "md",
            animated: true,
            duration: 10000,
          })
          .then((loading) => {
            this.loading = true;
            loading.present().then();
            loading.onDidDismiss().then(() => {
              if (this.loading) {
                this.loadingController.dismiss().then();
                this.subs = null;
              }
            });
          });
      }
    });
  }

  _stopLoading() {
    this.loadingController.getTop().then((hasLoading) => {
      if (hasLoading) {
        this.loadingController.dismiss().then();
        this.loading = false;
      }
    });
  }
}
