import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, ToastController } from "@ionic/angular";
import { HttpClient } from "@angular/common/http";
import { httpHeaders } from "../../pages/auth/auth.service";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { CountryResponse } from "../models/country";
import { Camera, CameraOptions } from "@awesome-cordova-plugins/camera/ngx";

@Injectable({
  providedIn: "root",
})
export class UtilsService {

  options: CameraOptions = {
    quality: 100,
    destinationType: this.camera.DestinationType.FILE_URI,
    encodingType: this.camera.EncodingType.JPEG,
    mediaType: this.camera.MediaType.PICTURE
  }

  constructor(
    public httpClient: HttpClient,
    public router: Router,
    public toastCtrl: ToastController,
    public alertCtrl: AlertController,
    public camera: Camera
  ) {}

  async presentToast(
    header: string,
    color = "success",
    icon = "alert-circle-outline",
    [textButton, roleButton] = ["Cerrar", "close"],
    position: "top" | "bottom" | "middle" = "bottom",
    func = () => {},
    duration: number = 2000
  ) {
    const toast = await this.toastCtrl.create({
      header,
      color,
      icon,
      position,
      duration,
      buttons: [
        {
          text: textButton,
          role: roleButton,
          handler: func,
        },
      ],
    });

    await toast.present();
  }

  async sendError(text) {
    const alert = await this.alertCtrl.create({
      header: "Error",
      message: text,
      buttons: ["OK"],
    });

    await alert.present();
  }

  async sendOkey(text) {
    const alert = await this.alertCtrl.create({
      header: "Exito",
      message: text,
      buttons: ["OK"],
    });

    await alert.present();
  }

  public getCountries(): Promise<any> {
    return this.httpClient
      .get(environment.apiUrl + "location/countries", httpHeaders)
      .pipe(
        map((data: CountryResponse) => {
          if (data.status === "200") {
            return data.result.data;
          } else {
            this.presentToast(
              `${data.status}  ${data.description}`,
              "danger"
            ).then();
            return false;
          }
        })
      )
      .toPromise();
  }

  public getCities(countryId): Promise<any> {
    return this.httpClient
      .get(
        environment.apiUrl + "location/cities_by_country/" + countryId,
        httpHeaders
      )
      .pipe(
        map((data: CountryResponse) => {
          if (data.status === "200") {
            return data.result.data;
          } else {
            this.presentToast(
              `${data.status}  ${data.description}`,
              "danger"
            ).then();
            return false;
          }
        })
      )
      .toPromise();
  }

  public takePhoto() {
    this.camera.getPicture(this.options).then((imageData) => {
      // imageData is either a base64 encoded string or a file URI
      // If it's base64 (DATA_URL):
      let base64Image = 'data:image/jpeg;base64,' + imageData;
    }, (err) => {
      // Handle error
    });
  }
}
