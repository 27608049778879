import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { LoadingController, MenuController, Platform } from "@ionic/angular";
import { Router } from "@angular/router";

import { catchError, map } from "rxjs/operators";
import { BehaviorSubject, throwError } from "rxjs";
import { environment } from "../../../../environments/environment";
import { UtilsService } from "../../../core/services/utils.service";
import { AuthService, httpHeaders } from "../../auth/auth.service";
import { LoadingService } from "../../../core/services/loading.service";

interface MaterialsResponse {
  status: string;
  description: string;
  result: Material[];
}

export interface Material {
  Id: string;
  CreationDate: string;
  ModificationDate: string;
  Name: string;
  Quantity: number;
  MinPrice: number;
  MaxPrice: number;
  DifferenceRelation: number;
  CustomPrice: number;
  Acronym: string;
  Symbol: string;
  AlertText: string;
  Description: string;
  Status: string;
  TypeId: number;
  TypeName: string;
}

export interface MaterialType {
  Id: string;
  Name: string;
}

@Injectable({
  providedIn: "root",
})
export class MaterialsService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  token = "";

  constructor(
    private httpClient: HttpClient,
    private platform: Platform,
    private router: Router,
    private loadingCtrl: LoadingController,
    private utilsService: UtilsService,
    public menuCtrl: MenuController,
    private loadingService: LoadingService,
    private authService: AuthService
  ) {}

  /**
   * Obtener todos los materiales
   */
  public getMaterials(search): Promise<any> {
    return this.httpClient
      .get(environment.apiUrl + "material?search=" + search, httpHeaders)
      .pipe(
        map(async (data: any) => {
          if (data.status === "200") {
            return data.result.data as Material;
          } else {
            this.authService.logOutLocalIfTokenExpired(data);
            this.utilsService
              .presentToast(
                data.description,
                "danger"
              )
              .then();
          }
        }),
        catchError(this.handleError)
      )
      .toPromise();
  }

  /**
   * Obtener material por id
   */
  public getMaterialById(materialId): Promise<any> {
    return this.httpClient
      .get(environment.apiUrl + "material/" + materialId, httpHeaders)
      .pipe(
        map(async (data: any) => {
          if (data.status === "200") {
            return data.result as Material;
          } else if (data.status !== "200" && isNaN(data.status)) {
            this.utilsService
              .presentToast(
                data.description,
                "danger"
              )
              .then();
          } else {
            this.authService.logOutLocalIfTokenExpired(data);
            this.utilsService
              .presentToast(
                data.description,
                "danger"
              )
              .then();
          }
        }),
        catchError(this.handleError)
      )
      .toPromise();
  }

  /**
   * Añadir material nuevo
   */
  addMaterial(material): Promise<any> {
    return this.httpClient
      .post(environment.apiUrl + "material", material, httpHeaders)
      .pipe(
        map((data: MaterialsResponse) => {
          if (data.status === "200") {
            this.utilsService
              .presentToast(
                "Material creado correctamente",
                "success"
              )
              .then(() => {
                this.router
                  .navigate(["/materials"], { replaceUrl: true })
                  .then();
              });
          } else {
            this.authService.logOutLocalIfTokenExpired(data);
            this.utilsService
              .presentToast(
                data.description,
                "danger"
              )
              .then(() => {});
          }
        }),
        catchError(this.handleError)
      )
      .toPromise();
  }

  /**
   * Añadir tipo de material
   */
  addMaterialType(Name): Promise<any> {
    return this.httpClient
      .post(environment.apiUrl + "materialType", { Name }, httpHeaders)
      .pipe(
        map((data: any) => {
          if (data.status === "200") {
            this.utilsService
              .presentToast(
                "Tipo de material creado correctamente",
                "success"
              )
              .then(() => {});
            return data.result;
          } else {
            this.authService.logOutLocalIfTokenExpired(data);
            this.utilsService
              .presentToast(
                data.description,
                "danger"
              )
              .then(() => {});
          }
        }),
        catchError(this.handleError)
      )
      .toPromise();
  }

  /**
   * Actualizar material
   */
  updateMaterial(material, materialId): Promise<any> {
    return this.httpClient
      .put(environment.apiUrl + "material/" + materialId, material, httpHeaders)
      .pipe(
        map((data: MaterialsResponse) => {
          if (data.status === "200") {
            this.utilsService
              .presentToast(
                "Material actualizado correctamente",
                "success"
              )
              .then(() => {
                this.router
                  .navigate(["/materials"], { replaceUrl: true })
                  .then();
              });
          } else {
            this.authService.logOutLocalIfTokenExpired(data);
            this.utilsService
              .presentToast(
                data.description,
                "danger"
              )
              .then(() => {});
          }
        }),
        catchError(this.handleError)
      )
      .toPromise();
  }

  /**
   * Desactivar un material
   */
  deleteMaterial(materialId): Promise<any> {
    return this.httpClient
      .delete(environment.apiUrl + "material/" + materialId, httpHeaders)
      .pipe(
        map((data: MaterialsResponse) => {
          if (data.status === "200") {
            this.utilsService
              .presentToast(
                "Material eliminado correctamente",
                "success"
              )
              .then();
          } else {
            this.authService.logOutLocalIfTokenExpired(data);
            this.utilsService
              .presentToast(
                data.description,
                "danger"
              )
              .then();
            this.isAuthenticated.next(false);
          }
        }),
        catchError(this.handleError)
      )
      .toPromise();
  }

  /**
   * Obtener todos los tipos de materiales
   */
  public getAllTypes(search): Promise<any> {
    return this.httpClient
      .get(environment.apiUrl + "materialType?search=" + search, httpHeaders)
      .pipe(
        map(async (data: any) => {
          if (data.status === "200") {
            return data.result.data as MaterialType[];
          } else {
            this.authService.logOutLocalIfTokenExpired(data);
            this.utilsService
              .presentToast(
                data.description,
                "danger"
              )
              .then();
          }
        }),
        catchError(this.handleError)
      )
      .toPromise();
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code {error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      () => new Error("Something bad happened; please try again later.")
    );
  }
}
