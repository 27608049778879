import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { IonicModule } from "@ionic/angular";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { HttpConfigInterceptor } from "./core/security/interceptors/interceptor";
import { AppVersion } from "@awesome-cordova-plugins/app-version/ngx";
import { Network } from "@awesome-cordova-plugins/network/ngx";
import { LayoutComponentsModule } from "./components/layout/layout.components.module";
import { HomeComponent } from "./pages/home/home.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import {
  FontAwesomeModule,
  FaIconLibrary,
} from "@fortawesome/angular-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FileOpener } from "@awesome-cordova-plugins/file-opener/ngx";
import { DatePipe } from "@angular/common";
import { Camera } from "@awesome-cordova-plugins/camera/ngx";
import { IonicStorageModule } from "@ionic/storage-angular";

import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthService } from "./pages/auth/auth.service";

@NgModule({
  imports: [
    ScrollingModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    LayoutComponentsModule,
    FontAwesomeModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    BrowserAnimationsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent, HomeComponent],
  providers: [
    InAppBrowser,
    SplashScreen,
    StatusBar,
    AppVersion,
    Network,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    FileOpener,
    DatePipe,
    Camera
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab, far);
  }
}
