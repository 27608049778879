import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-default-list",
  templateUrl: "./default-list.component.html",
  styleUrls: ["./default-list.component.css"]
})
export class DefaultListComponent {
  @Input() items;
  @Input() columns;
  @Output() delete = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() print = new EventEmitter();


  doDelete(event) {
    this.delete.emit(event);
  }

  doEdit(event) {
    this.edit.emit(event);
  }

  doPrint(event) {
    this.print.emit(event);
  }
}
