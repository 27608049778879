import { Component, EventEmitter, Output } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { MaterialsService } from "../../../../../pages/principal/materials/materials.service";

@Component({
  selector: "app-popover-create-material",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.css"],
})
export class CreateComponent {
  @Output() createTypeEvent = new EventEmitter();

  constructor(
    private alertController: AlertController,
    private materialService: MaterialsService
  ) {}

  async createType() {
    const alert = await this.alertController.create({
      header: "Añadir tipo de material",
      inputs: [
        {
          name: "Name",
          type: "text",
          placeholder: "Introduce el nombre",
        },
      ],
      buttons: [
        {
          text: "¡Cancelar!",
          role: "cancel",
          handler: () => {},
        },
        {
          text: "¡Añadir!",
          handler: (res) => {
            this.materialService.addMaterialType(res.Name).then((type) => {
              this.createTypeEvent.emit(true);
            });
          },
        },
      ],
    });

    await alert.present();
  }
}
