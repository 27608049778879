<ion-app [class.dark-theme]="dark">
  <ion-menu contentId="main-content" swipe-gesture="true">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>Menu</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div *ngFor="let p of appPages; let i = index">
        <ion-list-header *ngIf="p.typePermit === this.userData?.typeId && i === 0"> Principal </ion-list-header>
        <div *ngIf="p.typePermit === this.userData?.typeId">
          <ion-menu-toggle *ngIf="p.url">
            <ion-item class="pointer" [routerLink]="p.url" routerDirection="root" routerLinkActive="active"
              [class.active-parent]="p.open">
              <fa-icon [icon]="['fas', p.icon]" slot="start"></fa-icon>
              <ion-label> {{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-item class="pointer" *ngIf="p.children?.length > 0" (click)="p.open = !p.open"
            [class.active-parent]="p.open" detail="false">
            <ion-icon *ngIf="!p.open" name="chevron-forward-outline" slot="start"></ion-icon>
            <ion-icon *ngIf="p.open" name="chevron-down-outline" slot="start"></ion-icon>
            <ion-label>{{ p.title }}</ion-label>
          </ion-item>

          <ion-list *ngIf="p.open">
            <ion-menu-toggle>
              <ion-item class="sub-item pointer" *ngFor="let sub of p.children" [routerLink]="sub.url"
                routerDirection="root" routerLinkActive="active">
                <fa-icon [icon]="['fas', sub.icon]" slot="start" size="1x"></fa-icon>
                <ion-label> {{ sub.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </div>
      </div>
      <div *ngIf="checkLoginStatus()">
        <ion-list-header> Horas </ion-list-header>
        <ion-menu-toggle auto-hide="false">
          <ion-item routerLink="/fichar" routerDirection="root" routerLinkActive="active" class="pointer">
            <ion-icon slot="start" name="time-outline"></ion-icon>
            <ion-label> Fichar </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </div>
      <ion-list-header> Perfil </ion-list-header>
      <ion-menu-toggle auto-hide="false">
        <ion-item class="pointer" *ngIf="checkLoginStatus()">
          <ion-icon slot="start" name="happy-outline"></ion-icon>
          <ion-label> Perfil </ion-label>
        </ion-item>
        <ion-item class="pointer" *ngIf="!checkLoginStatus()">
          <ion-icon slot="start" name="enter-outline"></ion-icon>
          <ion-label> Iniciar sesión </ion-label>
        </ion-item>
        <ion-item class="pointer" *ngIf="checkLoginStatus()" (click)="logout()">
          <ion-icon slot="start" name="exit-outline"></ion-icon>
          <ion-label> Cerrar sesión </ion-label>
        </ion-item>
      </ion-menu-toggle>
    </ion-content>
  </ion-menu>

  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-app>