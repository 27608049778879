import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { LoadingController, MenuController, Platform } from "@ionic/angular";
import { Router } from "@angular/router";

import { catchError, map } from "rxjs/operators";
import { BehaviorSubject, EMPTY, Observable, lastValueFrom, of, throwError } from "rxjs";
import { Storage } from "@ionic/storage-angular";
import { environment } from "../../../environments/environment";
import { UtilsService } from "../../core/services/utils.service";
import { UntypedFormGroup } from "@angular/forms";
import { Fichaje, FichajeResponse, LoginResponse, LogoutResponse } from "./models/auth.models";
import { Client } from "../principal/clients/clients.service";

export const httpHeaders = {
  withCredentials: false,
  "Cache-Control":
    "no-cache, no-store, must-revalidate, post-check=0, pre-check=0",
  Pragma: "no-cache",
  Expires: "0"
};

const TOKEN_KEY = "token";
const USER = "user";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  userData: BehaviorSubject<{name: string, typeId: string}> = new BehaviorSubject<{name: string, typeId: string}>(null);
  token = "";

  constructor(
    private httpClient: HttpClient,
    private platform: Platform,
    private router: Router,
    private loadingCtrl: LoadingController,
    private utilsService: UtilsService,
    private storage: Storage,
    public menuCtrl: MenuController
  ) {
    this.loadToken().then();
  }

  async loadToken() {
    await this.storage.create();
    const token = await this.storage.get(TOKEN_KEY);
    const userData = await this.storage.get(USER);
    if (token && userData) {
      this.token = token;
      this.isAuthenticated.next(true);
      this.userData.next(userData);
      this.router.navigate(["/"], { replaceUrl: true }).then();
    } else {
      this.isAuthenticated.next(false);
    }
  }

  // ----- INICIAR SESIÓN ----- //
  public logIn(formLogin: UntypedFormGroup): Observable<Promise<void>> {
    if (formLogin.valid && !this.isLogged()) {
      return this.httpClient
        .post(
          environment.apiUrl + "authentication/login",
          formLogin.value,
          httpHeaders
        )
        .pipe(
          map(async (data: LoginResponse) => {
            if (data.status === "200") {
              const userStore = {
                name: data.result.user.name,
                typeId: data.result.user.type_id
              };
              this.storage.set(TOKEN_KEY, data.result.token).then(() => {
                this.storage.set(USER, userStore);
                this.menuCtrl.enable(true).then();
                this.loadToken().then();
              });
            } else {
              this.utilsService
                .presentToast(
                  data.description,
                  "danger"
                )
                .then();
              this.isAuthenticated.next(false);
            }
          }),
          catchError(this.handleError)
        );
    } else {
      this.utilsService
        .presentToast(
          "Ya has iniciado sesión",
          "danger",
          "alert-circle-outline",
          ["Inicio", "cancel"],
          "bottom",
          () => {
            this.router.navigate(["/"], { replaceUrl: true }).then();
          },
          2000
        )
        .then();
    }
  }

  public logout() {
    return this.httpClient
      .get(environment.apiUrl + "authentication/logout", httpHeaders)
      .pipe(
        map((data: LogoutResponse) => {
          if (data.status === "200" || data.status.startsWith("E")) {
            this.isAuthenticated.next(false);
            this.userData.next(null);
            this.storage.remove(USER);
            return this.storage.remove(TOKEN_KEY);
          } else {
            this.utilsService
              .presentToast(
                `${data.status}  ${data.description}`,
                "danger"
              )
              .then();
            return false;
          }
        })
      );
  }

  public logoutLocal() {
    this.isAuthenticated.next(false);
    this.userData.next(null);
    this.storage.remove(USER);
    return this.storage.remove(TOKEN_KEY);
  }

  logOutLocalIfTokenExpired(data) {
    if (data.status.startsWith("E")) {
      this.logoutLocal().then(() => {
        this.router.navigate(["/login"], { replaceUrl: true }).then();
      });
    }
  }

  /**
   * Método que devuelve true o false si el usuario está logueado
   */
  public isLogged() {
    let isLogged = false;
    this.isAuthenticated.asObservable().subscribe((data) => {
      isLogged = data;
    });

    return isLogged;
  }

  /**
   * Método que devuelve el token de la sesión
   */
  public getToken() {
    return this.token;
  }

  /**
   * Método que devuelve los datos del usuario
   */
  public getUserData() {
    return this.storage.get(USER);
  }

  /**
   * Método que devuelve los fichajes del usuario
   */
  public getFichaje() {
    return this.httpClient
      .get(environment.apiUrl + "authentication/fichaje", httpHeaders)
      .pipe(
        map(async (data: FichajeResponse) => {
          if (data.status === "200") {
            return data.result;
          } else {
            this.logOutLocalIfTokenExpired(data);
            this.utilsService
              .presentToast(
                data.description,
                "danger"
              )
              .then();
          }
        }),
        catchError(this.handleError)
      );
  }

  /**
   * Método que devuelve los fichajes del usuario
   */
  public getFichajeById(userId: string): Promise<any> {
    return lastValueFrom(this.httpClient
      .get(environment.apiUrl + "authentication/fichaje/" + userId, httpHeaders)
      .pipe(
        map(async (data: FichajeResponse) => {
          if (data.status === "200") {
            return data.result;
          } else {
            this.logOutLocalIfTokenExpired(data);
            this.utilsService
              .presentToast(
                data.description,
                "danger"
              )
              .then();
          }
        }),
        catchError(this.handleError)
      ));
  }

  public fichar(fichar: {In: boolean, Out: boolean, Latitud: number, Longitud: number, Distancia: number, TimeId?: string }): Observable<Promise<Fichaje>> {
  // ----- FICHAR ----- //
    return this.httpClient
      .post(
        environment.apiUrl + "authentication/fichar",
        fichar,
        httpHeaders
      )
      .pipe(
        map(async (data: FichajeResponse) => {
          if (data.status === "200") {
            return data.result;
          } else {
            this.logOutLocalIfTokenExpired(data);
            this.utilsService
              .presentToast(
                data.description,
                "danger"
              )
              .then();
          }
        }),
        catchError(this.handleError)
      );
  }



  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      () => new Error("Something bad happened; please try again later.")
    );
  }
}
