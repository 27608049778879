<ion-list>
  <h1 class="text-center">Listado</h1>
  <ion-card-content>
    <ion-row>
      <ion-col size="{{ col.size }}" *ngFor="let col of columns">
        <strong>{{ col.name }}</strong>
      </ion-col>
    </ion-row>
    <ion-card style="margin: 3px 0 10px 0" *ngFor="let item of items">
      <ion-card-content style="cursor: pointer; font-size: 12px; padding: 0 0">
        <ion-row>
          <ion-col size="{{ col.size }}" *ngFor="let col of columns">
            <span [ngSwitch]="col.type">
              <span (click)="doEdit(item)" *ngSwitchCase="'id'" style="color: black">
                <strong>{{ item[col.value] }}</strong>
              </span>
              <span (click)="doEdit(item)" *ngSwitchCase="'name'" style="color: black">
                <strong>{{
                  item[col.value[0]] + " " + (item[col.value[1]] ?? "")
                  }}</strong>
              </span>
              <span (click)="doEdit(item)" *ngSwitchCase="'mobile'" style="color: black">
                <strong>{{ item[col.value] }}</strong>
              </span>
              <span *ngSwitchCase="'info'" style="font-size: 20px">
                <ion-icon (click)="doDelete(item)" style="color: red; margin-right:10px" name="{{ col.icon[1] }}"></ion-icon>
                <ion-icon *ngIf="col.icon[2]" (click)="doPrint(item)" style="color: black" name="{{ col.icon[2] }}"></ion-icon>
              </span>
            </span>
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </ion-card-content>

  <div *ngIf="!this.items">
    <app-skeleton></app-skeleton>
  </div>
</ion-list>