import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeadingComponent } from "./heading/heading.component";
import { SharedModule } from "../../../shared/shared.module";
import { CreateComponent } from "./popovers/materials/create/create.component";
import { CreateToolTypeComponent } from "./popovers/tools/create/create-tool-type.component";
import { SkeletonComponent } from "./skeleton/skeleton.component";
import { DefaultListComponent } from "./default-list/default-list.component";

@NgModule({
  declarations: [HeadingComponent, CreateComponent, CreateToolTypeComponent, SkeletonComponent, DefaultListComponent],
  imports: [CommonModule, SharedModule],
  exports: [HeadingComponent, SkeletonComponent, DefaultListComponent],
})
export class LayoutComponentsModule {}
