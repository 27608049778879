<ion-header class="ion-no-border">
  <ion-toolbar color="primary">
    <ion-buttons *ngIf="!showSearchbar" slot="start">
      <ion-menu-button auto-hide="false"></ion-menu-button>
    </ion-buttons>
    <ion-title style="text-align:center" *ngIf="!showSearchbar">{{ title }}</ion-title>
    <ion-searchbar
      *ngIf="showSearchbar"
      showCancelButton="always"
      [(ngModel)]="queryText"
      (keydown)="search($event)"
      (ionCancel)="showSearchbar = false"
      placeholder="Search"
    ></ion-searchbar>
    <ion-buttons slot="end">
      <ion-button
        *ngIf="!showSearchbar && showSearchIcon"
        (click)="showSearchbar = true"
      >
        <ion-icon slot="icon-only" name="search"></ion-icon>
      </ion-button>
      <!--<ion-button *ngIf="!showSearchbar">
        <span>
          <ion-icon slot="icon-only" name="options"></ion-icon>
        </span>
      </ion-button>-->
      <ion-button *ngIf="buttonsMaterial" (click)="setMaterialOpen(true)"
        ><ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon
      ></ion-button>

      <ion-popover [isOpen]="isMaterialOpen" [dismissOnSelect]="true">
        <ng-template>
          <app-popover-create-material
            (createTypeEvent)="createType($event)"
          ></app-popover-create-material>
        </ng-template>
      </ion-popover>

      <ion-button *ngIf="buttonsTool" (click)="setToolOpen(true)"
        ><ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon
      ></ion-button>
      <ion-popover [isOpen]="isToolOpen" [dismissOnSelect]="true">
        <ng-template>
          <app-popover-create-tool-type
            (createToolTypeEvent)="createToolType($event)"
          ></app-popover-create-tool-type>
        </ng-template>
      </ion-popover>
      <ion-button *ngIf="showRefresh" (click)="refresh(true)"
      ><ion-icon slot="icon-only" name="reload-outline"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
