import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

/**
 * Modulo importado por gran parte de la aplicación, en él se importa y exporta to do lo que se va a usar
 * de seguido en todas las pantallas. En este caso por ejemplo: Material, Traducción... etc.
 */
@NgModule({
  declarations: [],
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule],
  exports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule],
})
export class SharedModule {}
