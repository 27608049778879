<ion-item *ngFor="let item of [1, 2, 3, 4, 5]">
  <ion-avatar slot="start">
    <ion-skeleton-text animated="true"></ion-skeleton-text>
  </ion-avatar>
  <ion-label>
    <h3>
      <ion-skeleton-text
        animated="true"
        style="width: 50%"
      ></ion-skeleton-text>
    </h3>
    <p>
      <ion-skeleton-text
        animated="true"
        style="width: 80%"
      ></ion-skeleton-text>
    </p>
    <p>
      <ion-skeleton-text
        animated="true"
        style="width: 60%"
      ></ion-skeleton-text>
    </p>
  </ion-label>
</ion-item>
