import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { LoadingController, MenuController, Platform } from "@ionic/angular";
import { Router } from "@angular/router";

import { catchError, map } from "rxjs/operators";
import { BehaviorSubject, throwError } from "rxjs";
import { environment } from "../../../../environments/environment";
import { UtilsService } from "../../../core/services/utils.service";
import { AuthService, httpHeaders } from "../../auth/auth.service";
import { LoadingService } from "../../../core/services/loading.service";

interface ToolsResponse {
  status: string;
  description: string;
  result: Tool[];
}

export interface Tool {
  Id: string;
  CreationDate: string;
  ModificationDate: string;
  Name: string;
  MinPrice: number;
  MaxPrice: number;
  DifferenceRelation: number;
  CustomPrice: number;
  Acronym: string;
  Symbol: string;
  AlertText: string;
  Description: string;
  Status: string;
  TypeId: number;
  TypeName: string;
  Quantity: number;
}

export interface ToolType {
  Id: string;
  Name: string;
}

@Injectable({
  providedIn: "root",
})
export class ToolsService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  token = "";

  constructor(
    private httpClient: HttpClient,
    private platform: Platform,
    private router: Router,
    private loadingCtrl: LoadingController,
    private utilsService: UtilsService,
    public menuCtrl: MenuController,
    private loadingService: LoadingService,
    private authService: AuthService
  ) {}

  /**
   * Obtener todos las herramientas
   */
  public getTools(search: string): Promise<any> {
    return this.httpClient
      .get(environment.apiUrl + "tool?search=" + search, httpHeaders)
      .pipe(
        map(async (data: any) => {
          if (data.status === "200") {
            return data.result.data as Tool;
          } else {
            this.authService.logOutLocalIfTokenExpired(data);
            this.utilsService
              .presentToast(
                data.description,
                "danger"
              )
              .then();
          }
        }),
        catchError(this.handleError)
      )
      .toPromise();
  }

  /**
   * Obtener todos los tipos de herramientas
   */
  public getAllTypes(search): Promise<any> {
    return this.httpClient
      .get(environment.apiUrl + "toolType?search=" + search, httpHeaders)
      .pipe(
        map(async (data: any) => {
          if (data.status === "200") {
            return data.result.data as ToolType[];
          } else {
            this.authService.logOutLocalIfTokenExpired(data);
            this.utilsService
              .presentToast(
                data.description,
                "danger"
              )
              .then();
          }
        }),
        catchError(this.handleError)
      )
      .toPromise();
  }

  /**
   * Obtener herramienta por id
   */
  public getToolById(toolId): Promise<any> {
    return this.httpClient
      .get(environment.apiUrl + "tool/" + toolId, httpHeaders)
      .pipe(
        map(async (data: any) => {
          if (data.status === "200") {
            return data.result as Tool;
          } else if (data.status !== "200" && isNaN(data.status)) {
            this.utilsService
              .presentToast(
                data.description,
                "danger"
              )
              .then();
          } else {
            this.authService.logOutLocalIfTokenExpired(data);
            this.utilsService
              .presentToast(
                data.description,
                "danger"
              )
              .then();
          }
        }),
        catchError(this.handleError)
      )
      .toPromise();
  }

  /**
   * Añadir herramienta nueva
   */
  addTool(tool): Promise<any> {
    return this.httpClient
      .post(environment.apiUrl + "tool", tool, httpHeaders)
      .pipe(
        map((data: ToolsResponse) => {
          if (data.status === "200") {
            this.utilsService
              .presentToast(
                "Herramienta creada correctamente",
                "success"
              )
              .then(() => {
                this.router.navigate(["/tools"], { replaceUrl: true }).then();
              });
          } else {
            this.authService.logOutLocalIfTokenExpired(data);
            this.utilsService
              .presentToast(
                data.description,
                "danger"
              )
              .then(() => {});
          }
        }),
        catchError(this.handleError)
      )
      .toPromise();
  }

  /**
   * Añadir tipo de herramienta
   */
  addToolType(Name): Promise<any> {
    return this.httpClient
      .post(environment.apiUrl + "toolType", { Name }, httpHeaders)
      .pipe(
        map((data: any) => {
          if (data.status === "200") {
            this.utilsService
              .presentToast(
                "Tipo de herramienta creado correctamente",
                "success"
              )
              .then(() => {});
            return data.result;
          } else {
            this.authService.logOutLocalIfTokenExpired(data);
            this.utilsService
              .presentToast(
                data.description,
                "danger"
              )
              .then(() => {});
          }
        }),
        catchError(this.handleError)
      )
      .toPromise();
  }

  /**
   * Actualizar herramienta
   */
  updateTool(tool, toolId): Promise<any> {
    return this.httpClient
      .put(environment.apiUrl + "tool/" + toolId, tool, httpHeaders)
      .pipe(
        map((data: ToolsResponse) => {
          if (data.status === "200") {
            this.utilsService
              .presentToast(
                "Herramienta actualizada correctamente",
                "success"
              )
              .then(() => {
                this.router.navigate(["/tools"], { replaceUrl: true }).then();
              });
          } else {
            this.authService.logOutLocalIfTokenExpired(data);
            this.utilsService
              .presentToast(
                data.description,
                "danger"
              )
              .then(() => {});
          }
        }),
        catchError(this.handleError)
      )
      .toPromise();
  }

  /**
   * Desactivar una herramienta
   */
  deleteTool(toolId): Promise<any> {
    return this.httpClient
      .delete(environment.apiUrl + "tool/" + toolId, httpHeaders)
      .pipe(
        map((data: ToolsResponse) => {
          if (data.status === "200") {
            this.utilsService
              .presentToast(
                "Herramienta eliminada correctamente",
                "success"
              )
              .then();
          } else {
            this.authService.logOutLocalIfTokenExpired(data);
            this.utilsService
              .presentToast(
                data.description,
                "danger"
              )
              .then();
            this.isAuthenticated.next(false);
          }
        }),
        catchError(this.handleError)
      )
      .toPromise();
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code {error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      () => new Error("Something bad happened; please try again later.")
    );
  }
}
