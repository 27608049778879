import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { PopoverController } from "@ionic/angular";
import { CreateComponent } from "../popovers/materials/create/create.component";

@Component({
  selector: "app-heading",
  templateUrl: "./heading.component.html",
})
export class HeadingComponent {
  @Input() title = "";
  @Input() showSearchbar = false;
  @Input() showRefresh = false;
  @Input() showSearchIcon = true;
  @Input() queryText = "";

  @Input() buttonsMaterial = false;
  @Input() buttonsTool = false;

  @Output() searchFunc = new EventEmitter();
  @Output() refreshFunc = new EventEmitter();
  @Output() execCreateType = new EventEmitter();
  @Output() execCreateToolType = new EventEmitter();

  isMaterialOpen = false;
  isToolOpen = false;
  route = "";

  constructor(private location: Location, private router: Router) {
    this.route = router.url;
  }

  setMaterialOpen(isOpen) {
    this.isMaterialOpen = isOpen;
  }
  setToolOpen(isOpen) {
    this.isToolOpen = isOpen;
  }

  search(event): void {
    if (event.key === "Enter") {
      this.searchFunc.emit(this.queryText);
    }
  }

  refresh(event): void {
    this.refreshFunc.emit(event);
  }

  createType(event) {
    this.execCreateType.emit(event);
  }

  createToolType(event) {
    this.execCreateToolType.emit(event);
  }
}
