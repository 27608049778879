<app-heading
  title="Inicio"
  [showSearchbar]="false"
  (searchFunc)="ngOnInit()"
></app-heading>
<ion-content fullscreen="true">
  <ion-card>
    <img src="https://ionicframework.com/docs/demos/api/card/madison.jpg" />
    <ion-card-header>
      <ion-card-subtitle>Destination</ion-card-subtitle>
      <ion-card-title>Madison, WI</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      Founded in 1829 on an isthmus between Lake Monona and Lake Mendota,
      Madison was named the capital of the Wisconsin Territory in 1836.
    </ion-card-content>
  </ion-card>
</ion-content>
